import React from 'react'
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo'

import Illustration from '../../../../svg/landing-page-main.svg'
import OurApproach from '../../../../components/landing/our-approach'
import LandingMainBanner from '../../../../components/landing/main-banner';
import ProblemOfSite from '../../../../components/landing/problem'
import Prices from '../../../../components/landing/prices'
import AboutSection from '../../../../components/page-parts/about-section';
import FormSection from '../../../../components/page-parts/form-section';
import Technologies from '../../../../components/page-parts/technologies'
import SitePageDescription from '../../../../components/landing/description'

const CorpSitePage = () => {

  const content = {
    ttlBlue: 'создание ',
    title: <span>корпоративного сайта</span>,
    subtitle: 'Созднание сайта - это первый шаг на пути к узнаваемости вашего бизнеса. Мы проектируем и разрабатываем высокопроизводительные сайты, которые приводят реальных клиентов',
    buttonText: 'подробнее',
    buttonLink: '#description-block',
  }

  const pageTitle = 'Создание корпоративного сайта'
  const pageDescription = 'Разработаем корпоративный сайт, который станет визитной карточкой вашей компании. Спроектируем запоминающийся, современный дизайн. Проработаем структуру сайта и контент каждой страницы. Настроим рекламу в Яндекс Директ и Google Ads. Закажите разработку своего сайта в веб-студии Ascent.'

  // const keywords = ''

  const aboutSite = {
    title: 'Создание корпоративного сайта «под ключ»',
    description: <>
    <p><strong>Корпоративный сайт</strong> давно является неотъемлемой частью любой компании. Он описывает все необходимые сведения, которые клиент должен знать о вашей деятельности. Ваши услуги, история развития, продукция, команда и сотрудники, контактная информация - все это клиент сможет найти на этом сайте.</p>
    
    <p>Наша команда поможет вам сделать ваш сайт узнаваемым. Мы проработаем структуру и контент сайта, разработаем современный дизайн "с изюминкой". При необходимости настроим рекламу и подключим CRM-систему.</p>
    </>,
    advantagesTitle: 'Преимущества корпоративного сайта',
    advantages: [
      'Улучшает имидж и доверие к компании',
      'Информативен и функционален',
      'Решает множество бизнес-задач',
      'Хорошая конверсия',
    ],
  }

  const tariffs = {
    base: 'от 90 000 ₽',
    business: 'от 110 000 ₽',
    premium: 'от 130 000 ₽',
  }

  const stages = [
    {
      name: 'Аналитика',
      desc: 'На подготовительном этапе разработки мы проводим анализ проектов конкурентов, их слабые и сильные места. Анализируем вашу целевую аудиторию, их боли и потребности. На основании собранной информации, предлагаем различные пути решения. ',
      id: 't1',
    },
    {
      name: 'Прототипирование',
      desc: 'Проектируем наглядную структуру сайта. Описываем поведение каждого элемента на странице.  Расставляем триггеры. Пишем продающие тексты.',
      id: 't2',
    },
    {
      name: 'Верстка и программирование',
      desc: 'Верстаем утвержденный вами дизай и структуру сайта. Адаптируем под все устройства. Добавляем анимацию и интерактивность элементам на странице. Добавляем сайту функциональность для удобного взаимодействия с ним.',
      id: 't3',
    },
    {
      name: 'Настройка сервисов аналитики',
      desc: 'Устанавливаем на сайт сервисы аналитики (Яндекс.Метрика, Google Analytics). Настраиваем цели для отслеживания показателей конверсии и результативности сайта. Сбор аналитики для улучшения KPI.',
      id: 't4',
    },
    {
      name: 'Запуск в продакшн',
      desc: 'Проводим полное тестирование сайта. Выявляем на ошибки и проблемы. После успешной проверки запускаем сайт в онлайн. Добавляем сайт в Яндекс.Вебмастер и Google Search Console для видимости его поисковиками.',
      id: 't5',
    },
    {
      name: 'Поддержка',
      desc: 'Мы за долгосрочные и честные отношения! После сдачи проекта, мы предлагаем вам заключить договор для дальнейшего сотрудничества, на протяжении которого мы будем продолжать работать над улучшением показателей вашего сайта, внедрять новые «фичи», тестировать различные гипотезы.',
      id: 't6',
    },
  ]

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <LandingMainBanner svg={<Illustration />} content={content} />
      <SitePageDescription content={aboutSite} />
      <Prices tariffs={tariffs} siteType="корпоративного сайта" />
      <ProblemOfSite />
      <OurApproach />
      <Technologies title="этапы разработки сайта" content={stages} mb={10}/>
      <AboutSection />
      <FormSection />
    </Layout>
  )
}

export default CorpSitePage
